.main{
    background-image: url("../static/bgmain.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 5px;
    color: bisque;
    height:60rem;
    width: 100%;
}
.text{
    font-size: 2rem;
    color: white;
    padding-top: 170px;
   padding-left: 20rem;
}
.text1{
    font-size: 7rem;
    color: white;

}
@media (max-width:1430px){
    .main{
        margin-right: 4px;
        color: bisque;
        height:56rem;
        width: 100%;
    }
    .text{
        font-size: 2rem;
        color: white;
        padding-top: 170px;
       padding-left: 10rem;
    }
    .text1{
        font-size: 5rem;
        color: white;
    }

}
@media(max-width:830px){
    .main{
        margin-right: 3px;
        color: bisque;
        height:66rem;
        width: 100%;
    }
    .text{
        font-size: 1.8rem;
        color: white;
        padding-top: 125px;
       padding-left: 10rem;
    }
    .text1{
        font-size: 4rem;
        color: white;
    
    }
}

@media(max-width:795px){
    .main{
        margin-right: 5px;
        color: bisque;
        height:49rem;
        width: 100%;
    }
    .text{
        text-align: center;
        font-size: 2.5rem;
        color: white;
        padding-top: 170px;
       padding-left: 0.7rem;
    }
    .text1{
        font-size: 3.5rem;
        color: white;
    }
}

@media(max-width:640px){
    .main{
        margin-right: 5px;
        color: bisque;
        height:35rem;
        width: 100%;
    }
    .text{
        text-align: center;
        font-size: 1rem;
        color: white;
        padding-top: 80px;
       padding-left: 0.5rem;
    }
    .text1{
        font-size: 2.6rem;
        color: white;
    }

}



